/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.title{
  color: #00796B !important;
}
ion-toolbar{--background:#00796B !important; color:#fff !important;}
ion-menu-button{color:#fff !important;}

.ion-color-green{
  --background:rgb(0, 121, 107) !important; color:#fff !important;
}
ion-menu.md ion-item.selected {
  --background: rgba(0, 121, 107, 0.14) !important;
  --color: #00796B !important
}
ion-menu.md  ion-item {
  color: #00796B !important;
}
ion-menu.md ion-item > active {
  color: #00796B !important;
}
ion-menu.md ion-item > a:hover {
  color: #00796B !important;
}
ion-menu.md ion-item.selected ion-icon {
  color:#00796B !important;
}


ion-item{--background: #E0F2F1; margin: 0 0 0 -15px !important;}
ion-card{--background: #E0F2F1; }
.action-sheet-button{color:#00796B !important}


.text-center{text-align: center !important;}
.mt-30{margin-top:30px;}
.text-sm{font-size: 12px;}
.text-black{color:#000000;}
.font-40{font-size:40px;}
.hide{display: none;}
ion-tab-button{--color-selected :#00796B !important; --color-focused:#00796B !important; --color-selected:#00796B !important;
}
ion-tab-bar{--background: #E0F2F1 !important;}
ion-tab-button{--background: #E0F2F1 !important;}
.ion-color-green{--background-activated:#00796B !important; --background-focused:#00796B !important;--background-activated-opacity:#00796B !important;  --background-focused-opacity:#00796B !important; }